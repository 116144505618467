import React, { useEffect, useState } from "react";
import axios from "axios";
export const Success = (data) => {
	const { CCAData, CCADataregion, relatedCCA = [] } = data;
	const [fetchedDatas, setFetchedDatas] = useState([]);
	useEffect(() => {
		const getCCAData = async () => {
			const CADSData = await Promise.allSettled(
				relatedCCA
					.filter((w) => w !== "Others" && !!w)
					.map(async (x) => {
						try {
							const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1/aapi/suburb/displayname/${x}`);
							return {
								phone: data.Phone,
								email: data.Email,
								CADS: data.CADS,
								id: data.ItemID,
							};
						} catch (error) {
							const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1/aapi/suburb/healthregion/${x}`);
							return {
								phone: data.Phone,
								email: data.Email,
								CADS: data.CADS,
								id: data.ItemID,
							};
						}
					})
			);
			const removeDuplicated = CADSData.filter(
				(x, i, acc) => acc.findIndex((y) => y?.value?.email === x?.value?.email) === i && x?.status === "fulfilled"
			);
			setFetchedDatas(removeDuplicated);
		};
		getCCAData();
		return () => {
			setFetchedDatas([]);
		};
	}, []);

	return (
		<div className="container">
			<div className="f-modal-icon f-modal-success animate">
				<span className="f-modal-line f-modal-tip animateSuccessTip"></span>
				<span className="f-modal-line f-modal-long animateSuccessLong"></span>
				<div className="f-modal-placeholder"></div>
				<div className="f-modal-fix"></div>
			</div>

			<div className="submitted">
				{navigator.onLine ? (
					<>
						<h2>Submitted!</h2>
						<p>Thank you for submitting a VSU Incident Report.</p>
					</>
				) : (
					<>
						<h2>Your form was not submitted</h2>
						<p>When you reconnect to the internet and return to the app, the form will submit automatically</p>
					</>
				)}

				{fetchedDatas.length > 1 && (
					<>
						<p>Your report has been sent to the following CCA's:</p>
						<ul>
							{fetchedDatas.map((x) => (
								<li key={x?.value?.id} style={{ width: "fit-content", margin: "0 auto" }}>
									<strong>{x?.value?.CADS}</strong>
								</li>
							))}
						</ul>
						<p>
							If you do not receive a phone call or email within 72 hours
							<br /> acknowledging receipt of this report, please contact one of the following CCA's:
						</p>

						{fetchedDatas.map((x) => (
							<li key={x?.value?.id} style={{ marginTop: 8 }}>
								<b> {x?.value?.CADS}</b> <br /> via <a href={`tel:${x?.value?.phone}`}>{x?.value?.phone}</a> or email
								<a href={`mailto:${x?.value?.email}`}>{" " + x?.value?.email}</a>
							</li>
						))}
						<p className="mt-4">You may also be contacted if further information is required.</p>
						<p>
							If you have any comments or feedback about this app, please provide it{" "}
							<a target="_blank" style={{ textDecoration: "underline" }} href="https://vsu.mhc.wa.gov.au/contactfeedback/">
								here
							</a>
						</p>
					</>
				)}
				{fetchedDatas.length === 1 && (
					<>
						<p>
							Your report has been sent to the <b>{fetchedDatas[0]?.value?.CADS}</b>
						</p>
						<p>
							If you do not receive a phone call or email within 72 hours acknowledging receipt of this report, please contact the
							<b> {fetchedDatas[0]?.value?.CADS}</b> via{" "}
							<a href={`tel:${fetchedDatas[0]?.value?.phone}`}>{fetchedDatas[0]?.value?.phone}</a> or email{" "}
							<a href={`mailto:${fetchedDatas[0]?.value?.email}`}>{fetchedDatas[0]?.value?.email}</a>
						</p>
						<p>You may also be contacted if further information is required.</p>
						<p>
							If you have any comments or feedback about this app, please provide it{" "}
							<a target="_blank" style={{ textDecoration: "underline" }} href="https://vsu.mhc.wa.gov.au/contactfeedback/">
								here
							</a>
						</p>
					</>
				)}
			</div>
		</div>
	);
};
